import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './ImageCard.module.scss';

const ImageCard = memo((props) => {
    const { src, alt, ratio, srcSet, dataSizes, className } = props;

    return (
        <>
            <div
                key={src}
                className={`${styles.ImageCard} relative w-full ${className}`}
                style={ratio ? { paddingBottom: ratio } : {}}
            >
                {srcSet ? (
                    <img
                        key={src}
                        alt={alt}
                        className={`${styles.imageContent} lazyload absolute h-full w-full object-cover`}
                        data-sizes={dataSizes}
                        data-srcset={srcSet}
                        data-lowsrc={src}
                    />
                ) : (
                    <img
                        key={src}
                        alt={alt}
                        className={`${styles.imageContent} lazyload absolute h-full w-full object-cover`}
                        data-src={`${src}?auto=format&crop=focalpoint&fit=crop`}
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-expand="1000"
                    />
                )}
            </div>
        </>
    );
});

ImageCard.propTypes = {
    lowsrc: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dataSrcset: PropTypes.string,
    srcSet: PropTypes.string,
    dataSizes: PropTypes.string,
    className: PropTypes.string,
};

ImageCard.defaultProps = {
    lowsrc: null,
    src: null,
    alt: 'Content',
    ratio: 0,
    dataSrcset: null,
    srcSet: null,
    dataSizes: null,
    className: '',
};

export default ImageCard;
