import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, message, Modal } from 'antd';
import { addRecordToCache } from '~/utils/graphql';
import ConsumerCollectionsConnectionQuery from '~/queries/ConsumerCollectionConnectionQuery.graphql';
import { CreatePropertyToConsumerCollection, IsPropertyLovedQuery } from './Operations.graphql';

class CreateConsumerCollectionModal extends Component {
    static propTypes = {
        createPropertyToConsumerCollection: PropTypes.func,
        form: PropTypes.shape({
            getFieldDecorator: PropTypes.func,
            validateFieldsAndScroll: PropTypes.func,
        }),
        handleCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        showModal: PropTypes.bool,
        propertyId: PropTypes.string,
    };

    static defaultProps = {
        createPropertyToConsumerCollection: () => {},
        form: {
            getFieldDecorator: () => {},
            validateFieldsAndScroll: () => {},
        },
        handleCancel: () => {},
        onSubmit: () => {},
        showModal: false,
        propertyId: '',
    };

    state = {
        isLoadingSaveButton: false,
        formErrorCode: null,
        errorMessage: '',
    };

    getErrorMessage = collectionName => {
        let errorMessage = null;
        const { formErrorCode } = this.state;

        switch (formErrorCode) {
            case 'COLLECTION_NAME_ALREADY_EXIST':
                errorMessage = `${collectionName} was already created, Try to create a new one.`;
                break;
            default:
                break;
        }

        this.setState({ errorMessage });
    };

    handleSubmit = e => {
        e.preventDefault();
        const {
            form: { validateFieldsAndScroll },
            createPropertyToConsumerCollection,
            onSubmit,
            propertyId,
        } = this.props;

        this.setState({ isLoadingSaveButton: true });

        validateFieldsAndScroll((err, values) => {
            if (!err) {
                const collectionName = values.collectionName.trim();
                createPropertyToConsumerCollection(collectionName, propertyId)
                    .then(res => {
                        message.success('Collection successfully created.');
                        onSubmit(res);
                    })
                    .catch(error => {
                        const errors = error.graphQLErrors || [];
                        const formErrorCode = errors.length > 0 ? errors[0].extensions.code : null;

                        this.setState({ formErrorCode }, () => {
                            message.warning(this.getErrorMessage(collectionName), 2);
                        });
                    })
                    .finally(() => {
                        this.setState({ isLoadingSaveButton: false });
                    });
            }
        });
    };

    render() {
        const {
            form: { getFieldDecorator },
            showModal,
            handleCancel,
        } = this.props;

        const { isLoadingSaveButton, errorMessage } = this.state;

        return (
            <Modal
                title={<span className="text-center text-lg font-semibold">Create an inspiration</span>}
                style={{ top: 20 }}
                zIndex={1060}
                visible={showModal}
                destroyOnClose
                onCancel={() => handleCancel()}
                okText="Save"
                maskClosable={false}
                centered
                footer={null}
            >
                <>
                    {errorMessage && <Alert message={errorMessage} type="warning" showIcon className="mb-2" />}

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item>
                            {getFieldDecorator('collectionName', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Collection name is required',
                                    },
                                ],
                            })(<Input placeholder="Name your collection" size="large" />)}
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                className="w-full"
                                loading={isLoadingSaveButton}
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        );
    }
}

const CreateConsumerCollectionModalWithMutation = graphql(CreatePropertyToConsumerCollection, {
    name: 'createPropertyToConsumerCollection',
    options: props => {
        const result = [];

        if (props.propertyId) {
            result.push({
                query: IsPropertyLovedQuery,
                variables: { id: props.propertyId },
            });
        }

        return { refetchQueries: result };
    },
    props: ({ createPropertyToConsumerCollection }) => ({
        createPropertyToConsumerCollection: (collectionName, propertyId) =>
            createPropertyToConsumerCollection({
                variables: {
                    collectionName,
                    propertyId,
                },
                update: (store, { data: { createPropertyToConsumerCollection } }) => {
                    try {
                        addRecordToCache(
                            store,
                            ConsumerCollectionsConnectionQuery,
                            { filter: { first: 10 } },
                            data => ({
                                ...data,
                                consumerCollectionsConnection: {
                                    ...data.consumerCollectionsConnection,
                                    nodes: [
                                        createPropertyToConsumerCollection,
                                        ...data.consumerCollectionsConnection.nodes,
                                    ],
                                },
                            })
                        );
                    } catch (error) {
                        console.log(error);
                    }
                },
            }),
    }),
})(CreateConsumerCollectionModal);

export default Form.create()(CreateConsumerCollectionModalWithMutation);
