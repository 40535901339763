import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { faBath, faBed, faCar } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import styles from './MainFeatures.module.scss';

const Feature = memo((props) => {
    const { classNames, displayText, icon, data, text, displayTextClassName } = props;

    return (
        !!data && (
            <div
                className={`${classNames.feature} inline-block cursor-default`}
                title={text && text[0].toUpperCase() + text.slice(1)}
            >
                <div className="flex items-center">
                    <FA icon={icon} className={classNames.icon} />
                    <span className="leading-none">
                        &nbsp;
                        {data}
                    </span>
                </div>
                {displayText && (
                    <div className={displayTextClassName}>
                        {text}
                        {data > 1 && 's'}
                    </div>
                )}
            </div>
        )
    );
});

Feature.propTypes = {
    classNames: PropTypes.shape({
        feature: PropTypes.string,
        icon: PropTypes.string,
    }),
    displayText: PropTypes.bool,
    displayTextClassName: PropTypes.string,
    icon: PropTypes.shape({
        icon: PropTypes.array,
        iconName: PropTypes.string,
        prefix: PropTypes.string,
    }),
    data: PropTypes.number,
    text: PropTypes.string,
};

Feature.defaultProps = {
    classNames: {
        feature: '',
        icon: '',
    },
    displayText: true,
    displayTextClassName: 'font-bold uppercase text-xs',
    icon: faBed,
    data: 0,
    text: '',
};

const MainFeatures = (props) => {
    const { features, className, classNames, displayTextClassName } = props;
    const { bedrooms, bathrooms, totalParking } = features || {};

    return (
        <div className={`${styles.MainFeatures} leading-none ${className}`}>
            <Feature
                classNames={classNames}
                displayText={false}
                displayTextClassName={displayTextClassName}
                icon={faBed}
                data={bedrooms}
                text="Bed"
            />
            <Feature
                classNames={classNames}
                displayText={false}
                displayTextClassName={displayTextClassName}
                icon={faBath}
                data={bathrooms}
                text="Bath"
            />
            <Feature
                classNames={classNames}
                displayText={false}
                displayTextClassName={displayTextClassName}
                icon={faCar}
                data={totalParking}
                text="Parking"
            />
            {!bedrooms && !bathrooms && !totalParking && (
                <span className={`${styles.propertyFeaturePlaceholder} text-transparent`} />
            )}
        </div>
    );
};

MainFeatures.propTypes = {
    classNames: PropTypes.shape({
        icon: PropTypes.string,
        feature: PropTypes.string,
    }),
    displayTextClassName: PropTypes.string,
    className: PropTypes.string,
    features: PropTypes.shape({
        bedrooms: PropTypes.number,
        bathrooms: PropTypes.number,
        totalParking: PropTypes.number,
    }),
};
MainFeatures.defaultProps = {
    classNames: {
        icon: '',
        feature: `text-gray-600 ${styles.propertyFeature} border-r border-gray-200 pr-2 py-1 mr-2 inline-block`,
    },
    displayTextClassName: 'font-normal text-xs mt-1',
    className: '',
    features: {},
};

export default MainFeatures;
