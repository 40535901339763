import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import styles from './PropertyViewVideos.module.scss';

const PropertyViewVideos = (props) => {
    const { onCancel, video, visible } = props;

    const videoLink = (vid) => {
        let embedVideo = '';
        if (vid) {
            switch (true) {
                case vid.indexOf('youtube') >= 0: {
                    let parseVideo = vid.indexOf('/watch?v=') >= 0 ? vid.replace('/watch?v=', '/embed/') : vid;
                    parseVideo = parseVideo.startsWith('https') ? parseVideo : `https://${parseVideo}`;

                    embedVideo = (
                        <iframe
                            width="100%"
                            height="500px"
                            src={`${parseVideo
                                .replace('&feature=youtu.be', '')
                                .replace('&amp;feature=youtu.be', '')}?rel=0`}
                            title="Property Video"
                        />
                    );

                    break;
                }
                case vid.indexOf('youtu.be') >= 0: {
                    const splitVideo = vid.split('/');
                    const parseVideo = `https://www.youtube.com/embed/${splitVideo[splitVideo.length - 1]}?rel=0`;

                    embedVideo = <iframe width="100%" height="100%" src={parseVideo} title="Property Video" />;
                    break;
                }
                case vid.indexOf('dropbox') >= 0: {
                    let parseVideo = '';
                    if (vid.endsWith('dl=0')) {
                        parseVideo = vid.replace('dl=0', 'raw=1');
                    }

                    embedVideo = <iframe width="100%" height="100%" src={parseVideo} title="Property Video" />;
                    break;
                }
                default: {
                    // embedVideo = vid.startsWith('https') ? (
                    //     <iframe width="100%" height="500px" src={vid} title="Property Video" />
                    // ) : (
                    //     <a
                    //         href={vid}
                    //         target="_blank"
                    //         rel="noopener noreferrer"
                    //         alt="Property Video"
                    //         className="block center-align video-link text-xl text-gray-700 hover:text-gray-800 bg-blue-200 py-2 px-6 no-underline rounded muli"
                    //     >
                    //         Please click to view video
                    //     </a>
                    // );
                    embedVideo = (
                        <a
                            href={vid}
                            target="_blank"
                            rel="noopener noreferrer"
                            alt="Property Video"
                            className="center-align video-link muli block rounded bg-blue-200 py-2 px-6 text-xl text-gray-700 no-underline hover:text-gray-800"
                        >
                            Please click to view video
                        </a>
                    );
                }
            }
        }
        return embedVideo;
    };

    return (
        <Modal
            visible={visible}
            destroyonCancel
            destroyOnClose={true}
            onCancel={onCancel}
            footer={null}
            width={null}
            className={styles.propertyModalVideo}
        >
            {videoLink(video)}
        </Modal>
    );
};

PropertyViewVideos.propTypes = {
    onCancel: PropTypes.func,
    video: PropTypes.string,
    visible: PropTypes.bool,
};

PropertyViewVideos.defaultProps = {
    onCancel: () => {},
    video: '',
    visible: false,
};

export default PropertyViewVideos;
