/* eslint-disable max-len */
import { useQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Modal, Popover } from 'antd';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import clsx from 'clsx';
import GuestLoginForm from '~/components/GuestLoginForm';
import CreateConsumerCollectionModal from '~/components/modals/CreateConsumerCollectionModal';
import ConsumerCollectionsConnectionQuery from '~/queries/ConsumerCollectionConnectionQuery.graphql';
import { loginUser } from '~/actions';
import {
    AddPropertyToConsumerCollection,
    IsPropertyLovedQuery,
    RemovePropertyFromConsumerCollection,
} from './Operations.graphql';
import styles from './LoveHeart.module.scss';

const AddToInspiration = (props) => {
    const {
        visible,
        isLoved,
        className,
        propertyId,
        isLoading,
        handlePopover,
        handleLoveButton,
        handleCreateConsumerCollectionModal,
    } = props;
    const { data, error } = useQuery(ConsumerCollectionsConnectionQuery, {
        fetchPolicy: 'network-only',
        variables: { filter: { first: 10 } },
    });

    if (error) return 'error...';
    const { consumerCollectionsConnection } = data || {};
    const dataSource =
        consumerCollectionsConnection && consumerCollectionsConnection.nodes ? consumerCollectionsConnection.nodes : [];

    return (
        <Popover
            placement="bottomRight"
            trigger="click"
            visible={visible}
            onVisibleChange={() => handlePopover(true)}
            title={
                <div className="flex h-12 justify-between pt-3 text-lg">
                    <div className="font-semibold">Add to Inspirations</div>
                    <div className="ml-2 cursor-pointer" onClick={() => handlePopover(false)} aria-hidden>
                        <CloseOutlined />
                    </div>
                </div>
            }
            content={
                <>
                    <div className={clsx(styles.AddInspiration, 'overflow-y-auto')} style={{ maxHeight: 300 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={dataSource}
                            renderItem={(item) => (
                                <div>
                                    {item ? (
                                        <List.Item
                                            actions={[
                                                <FA
                                                    icon={faHeart}
                                                    className={`${className} heart-icon cursor-pointer ${
                                                        isLoading &&
                                                        item?.propertyIds.includes(propertyId) &&
                                                        styles.pulsate
                                                    } ${
                                                        item?.propertyIds &&
                                                        item?.propertyIds.includes(propertyId) &&
                                                        'is-loved is-heart'
                                                    }`}
                                                    onClick={() => handleLoveButton(item)}
                                                />,
                                            ]}
                                        >
                                            <List.Item.Meta className="title" title={item.name} />
                                        </List.Item>
                                    ) : null}
                                </div>
                            )}
                        />
                    </div>
                    <div
                        className="flex h-10 cursor-pointer justify-between border-t border-gray-500 pt-3 text-lg"
                        onClick={() => handleCreateConsumerCollectionModal(true)}
                        aria-hidden
                    >
                        <div>Create New</div>
                        <div>
                            <PlusOutlined />
                        </div>
                    </div>
                </>
            }
        >
            <FA
                icon={faHeart}
                className={`${className} heart-icon cursor-pointer ${isLoading && 'pulsate'} ${isLoved && 'is-loved'}`}
            />
        </Popover>
    );
};

AddToInspiration.propTypes = {
    visible: PropTypes.bool,
    isLoved: PropTypes.bool,
    className: PropTypes.string,
    propertyId: PropTypes.string,
    isLoading: PropTypes.bool,
    handlePopover: PropTypes.func,
    handleLoveButton: PropTypes.func,
    handleCreateConsumerCollectionModal: PropTypes.func,
};

AddToInspiration.defaultProps = {
    visible: false,
    isLoved: false,
    className: '',
    propertyId: '',
    isLoading: false,
    handlePopover: () => {},
    handleLoveButton: () => {},
    handleCreateConsumerCollectionModal: () => {},
};

const LoveHeart = (props) => {
    const { className, isAuthenticated, isLoved, onLoginUser, propertyId, userType } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isShowCreateConsumerCollectionModal, setIsShowCreateConsumerCollectionModal] = useState(false);
    const [isRecentlyLoggedIn, setIsRecentlyLoggedIn] = useState(false);
    const [isShowLoginModal, setIsShowLoginModal] = useState(false);
    const [isShowPopover, setIsShowPopover] = useState(false);
    const [addPropertyToConsumerCollection] = useMutation(AddPropertyToConsumerCollection);
    const [removePropertyFromConsumerCollection] = useMutation(RemovePropertyFromConsumerCollection);

    const handlePopover = (bool) => {
        setIsShowPopover(bool);

        if (isRecentlyLoggedIn) {
            setIsRecentlyLoggedIn(false);
        }
    };

    const handleLoginModal = (bool) => {
        setIsShowLoginModal(bool);
    };

    const handleLoginSuccess = (loginData) => {
        const { accessToken, expiresIn, refreshToken, user, type } = loginData;

        onLoginUser({
            accessToken,
            expiresIn,
            refreshToken,
            user,
            type,
        });

        setIsRecentlyLoggedIn(true);
        handlePopover(true);
        handleLoginModal(false);
    };

    const onLoginSuccessAccount = (loginData) =>
        new Promise((resolve) => {
            handleLoginSuccess(loginData);
            resolve(true);
        });

    const handleCreateConsumerCollectionModal = (bool) => {
        setIsShowCreateConsumerCollectionModal(bool);
    };

    const handleCreateConsumerCollectionSubmit = (isSuccess) => {
        if (isSuccess) {
            handleCreateConsumerCollectionModal(false);
        }
    };

    const handleLoveButton = async (collection) => {
        setIsLoading(true);

        if (collection.propertyIds.includes(propertyId)) {
            await removePropertyFromConsumerCollection({
                variables: { propertyId, collectionId: collection.id },
                refetchQueries: [
                    {
                        query: IsPropertyLovedQuery,
                        variables: { id: propertyId },
                    },
                ],
                update: (
                    store,
                    { data: { removePropertyFromConsumerCollection: removePropertyFromConsumerCollectionData } }
                ) => {
                    try {
                        if (!removePropertyFromConsumerCollectionData) {
                            return;
                        }
                        const data = store.readQuery({
                            query: ConsumerCollectionsConnectionQuery,
                            variables: { filter: { first: 10 } },
                        });

                        const index = data.consumerCollectionsConnection.nodes.findIndex(
                            ({ id }) => id === collection.id
                        );

                        if (index >= 0) {
                            const collectionData = data.consumerCollectionsConnection.nodes[index];

                            const collectionIndex = collectionData.propertyIds.findIndex((id) => id === propertyId);

                            if (collectionIndex >= 0) {
                                const newCollection = {
                                    ...collectionData,
                                    propertyIds: [
                                        ...collectionData.propertyIds.slice(0, collectionIndex),
                                        ...collectionData.propertyIds.slice(collectionIndex + 1),
                                    ],
                                };

                                const newRecords = [
                                    ...data.consumerCollectionsConnection.nodes.slice(0, index),
                                    newCollection,
                                    ...data.consumerCollectionsConnection.nodes.slice(index + 1),
                                ];

                                store.writeQuery({
                                    query: ConsumerCollectionsConnectionQuery,
                                    data: {
                                        ...data,
                                        consumerCollectionsConnection: {
                                            ...data.consumerCollectionsConnection,
                                            nodes: newRecords,
                                        },
                                    },
                                    variables: { filter: { first: 10 } },
                                });
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },
            });
        } else {
            await addPropertyToConsumerCollection({
                variables: { propertyId, collectionId: collection.id },
                refetchQueries: [
                    {
                        query: IsPropertyLovedQuery,
                        variables: { id: propertyId },
                    },
                ],
                update: (store, { data: { addPropertyToConsumerCollection: addPropertyToConsumerCollectionData } }) => {
                    try {
                        if (!addPropertyToConsumerCollectionData) {
                            return;
                        }
                        const data = store.readQuery({
                            query: ConsumerCollectionsConnectionQuery,
                            variables: { filter: { first: 10 } },
                        });

                        const index = data.consumerCollectionsConnection.nodes.findIndex(
                            ({ id }) => id === collection.id
                        );

                        if (index >= 0) {
                            const collectionData = data.consumerCollectionsConnection.nodes[index];

                            const newCollection = {
                                ...collectionData,
                                propertyIds: [...collectionData.propertyIds, propertyId],
                            };

                            const newRecords = [
                                ...data.consumerCollectionsConnection.nodes.slice(0, index),
                                newCollection,
                                ...data.consumerCollectionsConnection.nodes.slice(index + 1),
                            ];

                            store.writeQuery({
                                query: ConsumerCollectionsConnectionQuery,
                                data: {
                                    ...data,
                                    consumerCollectionsConnection: {
                                        ...data.consumerCollectionsConnection,
                                        nodes: newRecords,
                                    },
                                },
                                variables: { filter: { first: 10 } },
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },
            });
        }

        setIsShowCreateConsumerCollectionModal(false);
        setIsLoading(false);
    };

    if (isAuthenticated && userType !== 'CONSUMER') return <div />;

    return (
        <div className={clsx(styles.LoveHeart, 'inline-block')}>
            <CreateConsumerCollectionModal
                onSubmit={handleCreateConsumerCollectionSubmit}
                showModal={isShowCreateConsumerCollectionModal}
                handleCancel={() => handleCreateConsumerCollectionModal(false)}
                propertyId={propertyId}
            />
            <Modal
                wrapClassName="vertical-center-modal"
                visible={isShowLoginModal}
                onOk={() => handleLoginModal(false)}
                onCancel={() => handleLoginModal(false)}
                footer={null}
            >
                <GuestLoginForm onSuccess={handleLoginSuccess} onSuccessAccount={onLoginSuccessAccount} />
            </Modal>
            {!isAuthenticated && (
                <FA
                    icon={faHeart}
                    className={`${className} heart-icon cursor-pointer ${isLoading && 'pulsate'} ${
                        isLoved && 'is-loved'
                    }`}
                    onClick={() => handleLoginModal(true)}
                />
            )}
            {isAuthenticated && userType === 'CONSUMER' && (
                <AddToInspiration
                    visible={isRecentlyLoggedIn || isShowPopover}
                    isLoading={isLoading}
                    className={className}
                    isLoved={isLoved}
                    propertyId={propertyId}
                    handlePopover={handlePopover}
                    handleLoveButton={handleLoveButton}
                    handleCreateConsumerCollectionModal={handleCreateConsumerCollectionModal}
                />
            )}
        </div>
    );
};

LoveHeart.propTypes = {
    className: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    isLoved: PropTypes.bool,
    onLoginUser: PropTypes.func,
    propertyId: PropTypes.string.isRequired,
    userType: PropTypes.string,
};

LoveHeart.defaultProps = {
    className: 'text-xl',
    isAuthenticated: false,
    isLoved: false,
    onLoginUser: () => {},
    userType: '',
};

export default compose(
    connect(
        (state) => ({
            isAuthenticated: state.auth.isAuthenticated,
            userType: state.auth.type,
        }),
        (dispatch) => ({
            onLoginUser(accessToken) {
                dispatch(loginUser(accessToken));
            },
        })
    )
)(LoveHeart);
