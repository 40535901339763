import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import styles from './ImageCarousel.module.scss';

const ImageCarousel = memo((props) => {
    const {
        autoPlay,
        animationSpeed,
        infinite,
        arrows,
        dots,
        handleSwipeImage,
        children,
        ratio,
        maxDots,
        showCounterBox,
        className,
        draggable,
        clickToChange,
        keepDirectionWhenDragging,
    } = props;

    const [slide, setSlide] = useState(0);

    useEffect(() => {
        handleSwipeImage(slide);
    }, [handleSwipeImage, slide]);

    const onPrevSlide = () => {
        const index = slide === 0 ? children.length - 1 : slide - 1;
        setSlide(index);
    };

    const onNextSlide = () => {
        const index = slide === children.length - 1 ? 0 : slide + 1;
        setSlide(index);
    };

    const onSwipe = (index) => {
        if (index > children.length - 1) {
            setSlide(0);
        } else if (index < 0) {
            setSlide(children.length - 1);
        } else {
            setSlide(index);
        }
    };

    const onChangeDots = (index) => {
        setSlide(index);
    };

    return (
        <div className={`${styles.ImageCarousel} relative ${className}`} style={ratio ? { paddingBottom: ratio } : {}}>
            {arrows && (
                <div
                    className={`${styles.nav} ${styles.navPrev} ${slide <= 0 && !infinite ? `${styles.disabled}` : ''}`}
                    onClick={onPrevSlide}
                    aria-hidden
                >
                    <FA icon={faChevronLeft} className={`${styles.arrow} ${styles.prev}`} />
                </div>
            )}
            {autoPlay === false ? (
                <Carousel
                    value={slide}
                    onChange={onSwipe}
                    clickToChange={clickToChange}
                    keepDirectionWhenDragging={keepDirectionWhenDragging}
                    animationSpeed={animationSpeed}
                    infinite={infinite}
                    draggable={draggable}
                >
                    {clickToChange === true
                        ? children.map((child, index) => {
                              return (
                                  <div className="relative h-full w-full" key={index}>
                                      {child}
                                      <div className="absolute inset-0 top-0 flex justify-between ">
                                          <div className=" w-1/2" onClick={onPrevSlide} />
                                          <div className=" w-1/2" onClick={onNextSlide} />
                                      </div>
                                  </div>
                              );
                          })
                        : children}
                </Carousel>
            ) : (
                <Carousel autoPlay={autoPlay} animationSpeed={animationSpeed} infinite={infinite} draggable={false}>
                    {children}
                </Carousel>
            )}
            {dots && <Dots value={slide} onChange={onChangeDots} number={Math.min(children.length, maxDots)} />}
            {arrows && (
                <div
                    className={`${styles.nav} ${styles.navNext} ${
                        slide === children.length - 1 && !infinite ? `${styles.disabled}` : ''
                    }`}
                    onClick={onNextSlide}
                    aria-hidden
                >
                    <FA icon={faChevronRight} className={`${styles.arrow} ${styles.next}`} />
                </div>
            )}
            {showCounterBox && (
                <div className={`${styles.imageCounterBox} bottom-0 left-0 flex items-center justify-center`}>
                    {slide + 1} of {children.length}
                </div>
            )}
        </div>
    );
});

ImageCarousel.propTypes = {
    autoPlay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    animationSpeed: PropTypes.number,
    infinite: PropTypes.bool,
    arrows: PropTypes.bool,
    dots: PropTypes.bool,
    maxDots: PropTypes.number,
    handleSwipeImage: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showCounterBox: PropTypes.bool,
    className: PropTypes.string,
    draggable: PropTypes.bool,
    // minDraggableOffset: PropTypes.number,
};

ImageCarousel.defaultProps = {
    autoPlay: false,
    animationSpeed: 500,
    infinite: false,
    arrows: false,
    dots: false,
    maxDots: 5,
    handleSwipeImage: () => {},
    children: {},
    ratio: 0,
    showCounterBox: false,
    className: '',
    draggable: false,
    // minDraggableOffset: 0,
};

export default ImageCarousel;
