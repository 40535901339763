/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import Head from 'next/head';
import Link from 'next/link';
import { Button, Tooltip } from 'antd';
import { faVideo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash.isempty';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import clsx from 'clsx';
import LoveHeart from '~/components/LoveHeart/LoveHeart';
import PropertyStatusTag from '~/components/PropertyStatusTag';
import PropertyViewVideos from '~/components/modals/PropertyViewVideos';
import ImageCarousel from '~/components/ImageCarousel';
import ImageCard from '~/components/ImageCard';
import { trackEvent } from '~/lib/analytics';
import DefaultImage from '~/assets/default-property-image.svg';
import styles from './PropertyCarousel.module.scss';

dayjs.extend(utc);

const PropertyViewVideoAction = memo((props) => {
    const { videoLink, propertyId } = props;

    const [isShowModal, setIsShowModal] = useState(false);

    const handleVideosModal = () => {
        setIsShowModal(true);

        trackEvent('Viewed Search Result Video', { dimension_1: parseInt(propertyId, 10) });
    };

    return (
        <>
            <PropertyViewVideos video={videoLink} visible={isShowModal} onCancel={() => setIsShowModal(false)} />
            <Tooltip title="View video">
                <Button
                    style={{ border: '0px' }}
                    size="large"
                    shape="circle"
                    className={`${styles.action} p-0 font-bold`}
                    onClick={() => handleVideosModal()}
                >
                    <FA icon={faVideo} />
                </Button>
            </Tooltip>
        </>
    );
});

PropertyViewVideoAction.propTypes = {
    videoLink: PropTypes.string,
    propertyId: PropTypes.string,
};

PropertyViewVideoAction.defaultProps = {
    videoLink: '',
    propertyId: '',
};

const PropertyCarousel = ({
    reviewSlugUrl,
    collectionId,
    fromReview,
    isFromSearch,
    ofiTime,
    property,
    sr,
    isPropertyInView,
    showActions,
    isInfoBox,
    ratio,
    searchOptions,
    searchUrl,
    isFromFeaturedPropertyCard,
}) => {
    const {
        id,
        agents,
        agency,
        isLovedByConsumer,
        objects,
        ofiTimes,
        slugUrl,
        slugUrlPrefix,
        status,
        videoLink,
        address,
        uuid,
        isUnderOffer,
    } = property || {};

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [propertyInView, setPropertyInView] = useState(false);

    const filteredOfiTimes =
        ofiTime && ofiTimes.filter((time) => time.datesFrom && time.datesTo && dayjs(time.datesTo) >= dayjs());

    useEffect(() => {
        if (!isEmpty(objects[currentImageIndex])) {
            if (isPropertyInView !== propertyInView) {
                if (isPropertyInView) {
                    trackEvent('Viewed Search Result Image', {
                        dimension_1: parseInt(id, 10),
                        dimension_3: objects[currentImageIndex].s3Key,
                        dimension_4: objects[currentImageIndex].type,
                    });
                }
            }
        }

        setPropertyInView(isPropertyInView);
    }, [objects, isPropertyInView]);

    const cleanUrlParams = (params) =>
        Object.keys(params).reduce((acc, key) => {
            const item = params[key];

            if (!(item === null || item === undefined || item === '' || (Array.isArray(item) && item.length === 0))) {
                acc[key] = item;
            }
            return acc;
        }, {});

    const handleSwipeImage = (index) => {
        if (!isEmpty(objects[index])) {
            if (isPropertyInView) {
                trackEvent('Viewed Search Result Image', {
                    dimension_1: parseInt(id, 10),
                    dimension_3: objects[index].s3Key,
                    dimension_4: objects[index].type,
                });
            }
        }

        setCurrentImageIndex(index);
    };

    const propertyStatus = (status || 'current').toUpperCase();

    let propertyLink = {
        // as: searchOptions ? `/property/${slugUrl}?searchOptions=${searchOptions}` : `/property/${slugUrl}`,
        href: {
            pathname: `/${slugUrlPrefix}/[slug]`,
            query: cleanUrlParams({
                slug: slugUrl,
                sr,
                isFromSearch: isFromSearch === false ? null : true,
                searchOptions,
                searchUrl,
            }),
        },
    };

    if (fromReview) {
        propertyLink = {
            href: {
                pathname: `/review/[slug]`,
                query: { slug: reviewSlugUrl },
            },
        };
    }

    return (
        <>
            {['SOLD', 'OFFMARKET', 'LEASED'].includes(status) && (
                <PropertyStatusTag
                    isFromFeaturedPropertyCard={isFromFeaturedPropertyCard}
                    propertyStatus={propertyStatus}
                    propertyCard="PropertyCard"
                />
            )}
            {isUnderOffer && (
                <div
                    className={`absolute z-10 mx-4 ml-6 ${
                        ['SOLD', 'OFFMARKET', 'LEASED'].includes(status) ? 'mt-12' : 'mt-4'
                    }`}
                >
                    <div className="rounded px-2 py-1 text-xs text-white" style={{ backgroundColor: '#3490DC' }}>
                        Under Offer
                    </div>
                </div>
            )}

            {showActions ? (
                <div
                    className={`${styles.propertyCardActions} absolute top-0 right-0 z-20 m-3 flex flex-col items-center pr-4 lg:pr-1`}
                >
                    {!isInfoBox && (
                        <div className="inline-block rounded-full ">
                            <Button size="large" shape="circle" className={`${styles.action} p-0`}>
                                <LoveHeart propertyId={id} isLoved={isLovedByConsumer} collectionId={collectionId} />
                            </Button>
                        </div>
                    )}
                    {videoLink && (
                        <PropertyViewVideoAction
                            videoLink={videoLink}
                            propertyId={id}
                            agencyId={agency ? agency.id : null}
                            agent={agents}
                        />
                    )}
                </div>
            ) : null}

            <div className={`${styles.carouselContainer} block h-full w-full`}>
                <ImageCarousel
                    autoPlay={false}
                    animationSpeed={500}
                    infinite
                    arrows={objects && objects.length > 1}
                    dots={objects && objects.length > 1}
                    handleSwipeImage={handleSwipeImage}
                    isInfoBox={isInfoBox}
                    ratio={ratio}
                >
                    {objects && objects.length > 0 ? (
                        objects.map(({ url }) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Link {...propertyLink} key={url}>
                                <a className="block w-full">
                                    <ImageCard
                                        ratio={ratio}
                                        key={url}
                                        alt={address}
                                        layout="fill"
                                        dataSizes="auto"
                                        src={`${url}?fit=crop&crop=entropy&auto=format&w=70&h=50`}
                                        srcSet={`
                                        ${url}?w=227&fm=png&auto=format&q=70 227w,
                                        ${url}?w=375&fm=png&auto=format&q=70 375w,
                                        ${url}?w=400&fm=png&auto=format&q=70 400w,
                                        ${url}?w=600&fm=png&auto=format&q=70 500w,
                                        ${url}?w=600&fm=png&auto=format&q=70 600w,
                                        ${url}?w=700&fm=png&auto=format&q=70 700w
                                        `}
                                    />
                                </a>
                            </Link>
                        ))
                    ) : (
                        <div className="each-fade w-full">
                            <div className="w-full">
                                <Link {...propertyLink} key="default-image">
                                    <a>
                                        <DefaultImage alt="" />
                                    </a>
                                </Link>
                            </div>
                        </div>
                    )}
                </ImageCarousel>
            </div>
            <section className="absolute bottom-0 left-0 z-10 mb-1 flex flex-col items-end">
                {!!filteredOfiTimes.length && (
                    <section className={`${styles.ofiEntry} -mb-1 items-center bg-gray-200 py-1 px-2 font-medium`}>
                        Open&nbsp;
                        {dayjs.utc(filteredOfiTimes[0].datesFrom).format('ddd')}
                        &nbsp;
                        {dayjs.utc(filteredOfiTimes[0].datesFrom).format('D')}
                        &nbsp;
                        {dayjs.utc(filteredOfiTimes[0].datesFrom).format('MMM')}
                        &nbsp;
                        {dayjs.utc(filteredOfiTimes[0].datesFrom).format('hh:mm a')}
                    </section>
                )}
            </section>
        </>
    );
};

PropertyCarousel.propTypes = {
    reviewSlugUrl: PropTypes.string,
    collectionId: PropTypes.string,
    fromReview: PropTypes.bool,
    isFromSearch: PropTypes.bool,
    ofiTime: PropTypes.bool,
    property: PropTypes.shape({
        id: PropTypes.string,
        agents: PropTypes.array,
        agency: PropTypes.object,
        featuredTier: PropTypes.string,
        isLovedByConsumer: PropTypes.bool,
        isUnderOffer: PropTypes.bool,
        objects: PropTypes.array,
        ofiTimes: PropTypes.array,
        priceView: PropTypes.string,
        slugUrl: PropTypes.string,
        status: PropTypes.string,
        videoLink: PropTypes.string,
        type: PropTypes.string,
        // isUnderOffer: PropTypes.string,
    }),
    sr: PropTypes.string,
    isPropertyInView: PropTypes.bool,
    showActions: PropTypes.bool,
    isInfoBox: PropTypes.bool,
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    searchOptions: PropTypes.string,
    searchUrl: PropTypes.string,
    isFromFeaturedPropertyCard: PropTypes.bool,
};

PropertyCarousel.defaultProps = {
    reviewSlugUrl: '',
    collectionId: '',
    fromReview: false,
    isFromSearch: {},
    ofiTime: false,
    property: {},
    sr: '',
    isPropertyInView: false,
    showActions: true,
    isInfoBox: false,
    ratio: 0,
    searchOptions: '',
    searchUrl: '',
    isFromFeaturedPropertyCard: false,
};

export default PropertyCarousel;
