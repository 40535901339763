import React from 'react';
import PropTypes from 'prop-types';
import style1 from '~/pages/PropertyPage/PropertyPage.module.scss';
import style2 from '~/components/PropertyCard/PropertyCard.module.scss';

const PropertyStatusTag = (props) => {
    const { propertyStatus, propertyPage, propertyCard, isFromFeaturedPropertyCard } = props;

    let statusText = propertyStatus;

    if (propertyStatus === 'SOLD') {
        statusText = 'SOLD';
    } else if (propertyStatus === 'LEASED') {
        statusText = 'LEASED';
    } else if (propertyStatus === 'OFFMARKET') {
        statusText = 'OFF-MARKET';
    }

    return (
        <>
            {propertyPage && (
                <div className={`${style1.PropertyPage} absolute z-10 mx-4 my-4 md:mx-6 md:my-6 lg:mx-12`}>
                    <div
                        className="rounded px-4 py-2 text-xs text-white md:px-6 md:text-sm"
                        style={{ backgroundColor: '#f0ca00' }}
                    >
                        {statusText}
                    </div>
                </div>
            )}
            {propertyCard && (
                <div
                    className={`${style2.PropertyCard} absolute z-10 ml-6 ${
                        isFromFeaturedPropertyCard ? 'mx-4 mt-12' : 'm-4'
                    }`}
                >
                    <div className="rounded px-2 py-1 text-xs text-white" style={{ backgroundColor: '#f0ca00' }}>
                        {statusText}
                    </div>
                </div>
            )}
        </>
    );
};

PropertyStatusTag.propTypes = {
    propertyStatus: PropTypes.string,
    propertyPage: PropTypes.string,
    propertyCard: PropTypes.string,
    isFromFeaturedPropertyCard: PropTypes.bool,
};

PropertyStatusTag.defaultProps = {
    propertyStatus: '',
    propertyPage: '',
    propertyCard: '',
    isFromFeaturedPropertyCard: false,
};

export default PropertyStatusTag;
